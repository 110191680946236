<template>
  <div>获得图片验证码</div>
</template>

<script>
export default {

}
</script>

<style>

</style>